.CompetitionListCell{
    color: black;
    text-decoration: none;
}

.CompetitionListCell:hover{
    color: white;
}

.CompetitionCellText{
    color: inherit !important;
    text-decoration: inherit !important;
}

.CompetitionCellText:hover{
    color: inherit !important;
    text-decoration: inherit !important;
}
