body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page h1.format-icon:before {
    content: none;
}

.Toolbar {
    background-color: #082b9f;
    padding-block-end: 10px;
    padding-right: 20px;
    padding-block-start: 10px;
    color: white;
    display: block;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.LogInOutButton {
    color: white;
    text-decoration: underline;
    text-decoration-color: white;
    display: none;
}

.EditCell {
    width: auto;
}

.ErrorMessage {
    color: red;
}

.Logo {
    vertical-align: center;
    height: 100px;
    padding-left: 10px;
}

.NewSaveCancelButton {
    padding-top: 7.85px !important;
    padding-bottom: 7.85px !important;
}

.PaddedPage {
    padding: 10px;
}

.TextColored {
    color: #082b9f
}

@media only screen and (min-width: 700px) {
    .lgToolbar {
        text-align: right;
    }

    .lgLogInOutButton {
        display: inline-block;
    }

    .lgLogo {
        float: left;
    }
}
