.HeaderRow {
    background-color: grey;
    font-weight: bold;
}

.HeaderRowCell {
    font-weight: bold !important;
    padding: 8px !important;
}

.BodyRowCell {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.CountingScore {
    color: #1a9f1f !important;
}

.LineThroughRow {
    text-decoration: line-through;
}

.ApparatusResultCell {
    display: none !important;
}

.ExpandCell {
    display: table-cell !important;
}

@media only screen and (min-width: 700px) {
    .ApparatusResultCell {
        display: table-cell !important;
    }

    .ExpandCell {
        display: none !important;
    }
}